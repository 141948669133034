import {getApi, postApi} from "../../../../core/store/api";

const endpoints = {
  postChat: '/api/v1/agent/chat',
  initChat: '/api/v1/agent/init_chat',
};

export function postChatMessage(param) {
  var body = {
    message: param.content,
    org: param.org,
    chat_config: {
      session_id: param.session_id
    }
  }
  return postApi(endpoints.postChat, body)
}

export function getGreetingMessage(param) {
  return getApi(endpoints.initChat,{})
}