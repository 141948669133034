import axios from "axios";

export const apiUrl = (endpoint) => {
  const url = process.env.REACT_APP_API_BASE_URL || "http://localhost:8005"
  const path = process.env.API_PATH || ""
  return url + path + endpoint;
}

export function postApi(endpoint, data = {}) {
  return axios({
    method: "post",
    url: apiUrl(endpoint),
    data: data,
  });
}

export function getApi(endpoint, params = {}, headers = {}) {
  return axios({
    method: "get",
    url: apiUrl(endpoint),
    params: params,
  });
}

export function putApi(endpoint, data = {}) {
  return axios({
    method: "put",
    url: apiUrl(endpoint),
    data: data,
  });
}

export function deleteApi(endpoint, data = {}) {
  return axios({
    method: "delete",
    url: apiUrl(endpoint),
    data: data
  });
}