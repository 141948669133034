import React, { useState, useEffect } from "react";
import { formatDate } from "../../utils/formatDate";
import { ImageActions } from "./ImageActions";
import { MessageActions } from "./MessageActions";


export interface MessageContentProps {
  isOutgoing: boolean;
  timestamp: string;
  content: string;
  actions: string[];
  images: string[];
  urls: string[];
  table: string[];
  type: string;
}

export const MessageContent: React.FC<MessageContentProps> = ({
  isOutgoing,
  timestamp,
  content,
  actions,
  images = [],
  urls = [],
  table = [],
  type,
}) => {
  const alignmentClass = isOutgoing ? "items-end " : "items-start";
  const wMessage = isOutgoing
  ? "max-w-[70%] bg-gradient-to-r from-blue-500 to-blue-500 text-white font-medium text-[15px]  hover:shadow-lg"
  : "max-w-full bg-gray-100 text-gray-800 font-normal text-[15px] leading-[1.6] rounded-3xl shadow px-6 py-3";
  const messageUser = isOutgoing ? "":"whitespace-pre-wrap";

  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);

  const openFullscreen = (image: string) => setFullscreenImage(image);
  const closeFullscreen = () => setFullscreenImage(null);


  return (
    <div className="flex-col gap-1 md:gap-3">
      <div className="flex max-w-full flex-col flex-grow">
        <div className="min-h-8 text-message flex w-full flex-col items-end gap-2 whitespace-normal break-words">
          <div className={`flex w-full flex-col gap-1 empty:hidden ${alignmentClass}`}>
            <time className={`text-sm`}>{formatDate(timestamp)}</time>
            <div className={`relative ${wMessage} rounded-3xl bg-token-message-surface px-5 py-2.5`}>
              <div
                // className={`${messageUser}`}
              >
                {type === 'html' ? (
                  // Hiển thị dạng HTML khi type là 'html'
                  <div
                    dangerouslySetInnerHTML={{
                    __html: content
                      .replace(/```html|```/g, "")
                      .replace(/body\s*{[^}]*}/, ""),
                    }}
                  />
                ) : (
                  // Hiển thị như văn bản bình thường nếu không phải 'html'
                  <div>{content}</div>
                )}
              </div>


              {/* Display URL links */}
              {urls.length > 0 && (
                <div className="mt-2 flex flex-wrap gap-3">
                  {urls.map((url, index) => (
                    <a
                      key={index}
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="px-1 py-1 text-sm bg-gray-200/50 bg-white border border-gray-300 rounded-lg shadow-sm transition-all duration-300 transform hover:bg-blue-500 hover:text-white hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <span className="truncate">{new URL(url).hostname}</span>
                    </a>
                  ))}
                </div>
              )}
              {/* Display URL links */}
              {table.length > 0 && (
                <div className="flex-col gap-1 md:gap-3">
                  <div className="flex max-w-full flex-col flex-grow">
                    <div className="min-h-8 text-message flex w-full flex-col items-end gap-2 whitespace-normal break-words">
                      <div className={`flex w-full flex-col gap-1 empty:hidden ${alignmentClass}`}>
                        <div className={`relative ${wMessage} rounded-3xl bg-token-message-surface px-5 py-2.5`}>
                          <div className="mt-4">
                            <table className="min-w-full border-collapse">
                              <thead>
                                <tr>
                                  {Object.keys(table[0]).map((key) => (
                                    <th key={key} className="px-4 py-2 border">{key}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {table.map((row, rowIndex) => (
                                  <tr key={rowIndex}>
                                    {Object.entries(row).map(([key, value], cellIndex) => (
                                      <td key={cellIndex} className="px-4 py-2 border">{value}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {fullscreenImage && (
                    <div className="fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-75">
                      <img src={fullscreenImage} alt="Fullscreen" className="max-h-full max-w-full" />
                      <button
                        onClick={closeFullscreen}
                        className="absolute top-4 right-4 text-white text-2xl font-bold"
                      >
                        ✕
                      </button>
                    </div>
                  )}
                </div>
              )}

              {/* Display images with hover effect */}
              {images.length > 0 && (
                <div className="grid gap-4 grid-cols-2 my-2.5">
                  {images.slice(0, images.length).map((image, index) => (
                    <div key={index} className="group relative">
                      <ImageActions
                        image={image}
                        onFullscreen={openFullscreen}
                      />
                      <img src={image} alt={`Image ${index + 1}`} className="rounded-lg w-full h-full object-cover shadow-sm" />
                    </div>
                  ))}
                </div>
              )}

              {isOutgoing && <MessageActions isOutgoing={isOutgoing} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
