import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadMessages, addMessage, sendMessageRequest } from "../stores/actions";
import MessageInput from "../components/input/MessageInput";

import { MessageContainer } from "../components/messages/MessageContainer";

const Conversation: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Use params if organization data is passed via the route
  const { org } = useParams<{ org: string }>();

  // State for session ID and content
  const [sessionId, setSessionId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");

  // Messages from Redux state
  const messages = useSelector((state: any) => state.chatMessage.messages);

  const isInitialized = useRef(false);    // Add a ref to track initialization


  useEffect(() => {
    // On component mount, check for existing session_id in localStorage
    // var sessionId = localStorage.getItem("session_id");
    // if (!sessionId) {
    //   sessionId = generateSessionId();
    //   localStorage.setItem("session_id", sessionId);
    // } 

    // setSessionId(sessionId);
    const sessionId = "123";

    // Load messages if any
    if (messages.length === 0 && !isInitialized.current) {
      console.log("add initial message");
      const initialMessage = {
        id: sessionId,
        content: "Xin chào, tôi là trợ lý ảo trường Olympia, tôi có thể giúp gì cho bạn?",
        isOutgoing: false,
        timestamp: new Date().toISOString()
      };
      dispatch<any>(addMessage(initialMessage));
      isInitialized.current = true;
    }
  }, []);


  const generateSessionId = () => {
    return "_" + Math.random().toString(36).substr(2, 9);
  };

  // const loadMessagesData = (sessionId: string) => {
  //   dispatch<any>(loadMessages(sessionId));
  // };


  const handleSendMessage = async (content: string) => {
    if (!content.trim()) return;

    const message = { id: sessionId, content, isOutgoing: true, timestamp: new Date().toISOString() };
    await dispatch<any>(addMessage(message));
    setLoading(true);

    const params = { content, org, session_id: sessionId };

    try {
      await dispatch<any>(sendMessageRequest(params));
      //loadMessagesData(sessionId);
      setContent(""); // Clear the input
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex h-full w-full overflow-hidden transition-colors z-0">
      <div className="relative flex h-full w-full flex-col transition-width">
            <MessageContainer messages={messages} loading={loading} />
            <MessageInput onSendMessage={handleSendMessage} />
            <div className="relative w-full px-2 py-2 text-center text-xs text-token-text-secondary empty:hidden md:px-[60px]">
              <div className="min-h-2" />
            </div>
      </div>
    </div>
  );
};

export default Conversation;
