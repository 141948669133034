import React, { useEffect, useRef, useState } from "react";
import { Message } from "./Message";

export const MessageContainer: React.FC<{
  messages: { 
    content: string;
    isOutgoing: boolean;
    timestamp: string;
    images: string[];
    urls: string[],
    actions: string[],
    table: string[],
    type: string}[],
  loading: boolean,

}> = ({ messages, loading }) => {
  const endOfChatRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    endOfChatRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="h-full w-full">
        <div className="h-full relative">
          <div style={{ overflowY: "auto", width: "100%", height: "100%" }}>
            <div className="flex flex-col text-sm pb-9 justify-content">
              {messages.map((message, index) => (
                <Message
                  key={index}
                  isOutgoing={message.isOutgoing}
                  timestamp={message.timestamp}
                  content={message.content}
                  actions={message.actions}
                  images={message.images}
                  urls={message.urls}
                  table={message.table}
                  type={message.type}
                />
              ))}
              {loading && (
                <div className="flex justify-center mt-3">
                  <div className="dot-loading flex gap-1 text-[#007bff] text-lg">
                    <span className="animate-pulse">.</span>
                    <span className="animate-[pulse_1s_infinite_300ms]">.</span>
                    <span className="animate-[pulse_1s_infinite_600ms]">.</span>
                  </div>
                </div>
              )}
              <div ref={endOfChatRef} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
