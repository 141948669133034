import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';
import {chatSlice} from "../../app/modules/chat/stores/slice";

export const appReducer = combineReducers({
  chatMessage: chatSlice.reducer,
});

export const rootReducer = (state, action) => {
  // reset root state when user logout
  // if (action.type === auth.actionTypes.Logout) {
  //   return appReducer(undefined, action);
  // }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([]);
}
