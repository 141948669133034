// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom-focus-styles.scss */
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=url]:focus,
input[type=date]:focus,
input[type=time]:focus,
input[type=datetime-local]:focus,
input[type=month]:focus,
input[type=week]:focus,
select:focus,
textarea:focus {
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: #2563eb;
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/chat/styles/StartConversation.module.scss"],"names":[],"mappings":"AAAA,6BAAA;AACA;;;;;;;;;;;;;;EAcE,2BAAA;EACA,4BAAA;EACA,wBAAA;EACA,2GAAA;EACA,yGAAA;EACA,qBAAA;EACA,gBAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":["/* custom-focus-styles.scss */\ninput[type=\"text\"]:focus,\ninput[type=\"email\"]:focus,\ninput[type=\"password\"]:focus,\ninput[type=\"number\"]:focus,\ninput[type=\"search\"]:focus,\ninput[type=\"tel\"]:focus,\ninput[type=\"url\"]:focus,\ninput[type=\"date\"]:focus,\ninput[type=\"time\"]:focus,\ninput[type=\"datetime-local\"]:focus,\ninput[type=\"month\"]:focus,\ninput[type=\"week\"]:focus,\nselect:focus,\ntextarea:focus {\n  --tw-ring-offset-width: 0px;\n  --tw-ring-offset-color: #fff;\n  --tw-ring-color: #2563eb;\n  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);\n  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);\n  border-color: #2563eb;\n  box-shadow: none;\n  outline: 2px solid transparent;\n  outline-offset: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
