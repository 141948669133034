import {createSlice} from "@reduxjs/toolkit"

const initialChatState = {
  listLoading: false,
  actionsLoading: false,
  // messages: JSON.parse('[]'),
  messages: [],
};

export const callTypes = {
  list: 'list',
  action: 'action',
  add_message: 'add_message',
};

export const chatSlice = createSlice({
  name: "chat",
  initialState: initialChatState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
    },
    addMessage: (state, action) => {
      console.log("state", JSON.parse(JSON.stringify(state)));
      console.log("action", action);
      state.messages.push(action.payload);
      //localStorage.setItem('messages', JSON.stringify(state.messages));
    }
  },
});