import * as requestFromServer from "./api";
import { chatSlice, callTypes } from "./slice";
import {postChatMessage} from "./api";

const { actions } = chatSlice;
export const sendMessageRequest =
  (params) =>
    (dispatch) => {
      dispatch(actions.startCall({ callType: callTypes.list }));

      return requestFromServer
        .postChatMessage(params)
        .then((response) => {
          const res = response.data;
          const messageData = {
            id: params.session_id,
            content: res.content,
            isOutgoing: false,
            timestamp: new Date().toISOString(),
            images: res.images || [],
            urls: res.urls || [],
            table: res.data || [],
            type: res.type || [],
          };

            // console.log("messageTable:",messageTable)
            dispatch(addMessage(messageData));
        })
        .catch((error) => {
          dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
    };
export const getGreeting =
    (params) =>
        (dispatch) => {
            dispatch(actions.startCall({ callType: callTypes.list }));
            return requestFromServer
                .getGreetingMessage(params)
                .then((response) => {
                    const res = response.data;
                    dispatch(res.content);
                })
                .catch((error) => {
                    dispatch(actions.catchError({ error, callType: callTypes.list }));
                });
        };

export const loadMessages = (session_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.load_message }));
  return dispatch(actions.loadMessage(session_id))
};

export const addMessage = (message) => (dispatch) => {
  // const messages = JSON.parse(localStorage.getItem("messages") || "[]");
  // messages.push(message);
  // localStorage.setItem("messages", JSON.stringify(messages));
  return dispatch(actions.addMessage(message));

};
