import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {Provider} from "react-redux";
import store, {persistor} from "./core/store/store";
import {PersistGate} from "redux-persist/integration/react";
import {AppRoutes} from "./app/routing/AppRoutes";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppRoutes/>
                </PersistGate>
        </Provider>
    </React.StrictMode>
);

