import { Routes, Route, Outlet } from "react-router-dom";
import Conversation from "./pages/Conversation";

const ChatPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route path="/"
          element={
            <>
              <Conversation/>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default ChatPage;
