import React from "react";
import { MessageContent, MessageContentProps } from "./MessageContent";
import { MessageActions, MessageActionsProps } from "./MessageActions";

export interface MessageProps extends MessageContentProps, MessageActionsProps {
  isOutgoing: boolean;
  timestamp: string;
}

export const Message: React.FC<MessageProps> = ({
  isOutgoing,
  timestamp,
  content,
  actions,
  images,
  urls,
  table,
  type,
}) => {
  const alignmentClass = isOutgoing ? "items-end" : "items-start";

  return (
    <section
      className={`flex overflow-hidden flex-col justify-center ${alignmentClass} py-4 w-full max-md:max-w-full`}
    >
      <div className="m-auto text-base px-3 md:px-4 w-full md:px-5 lg:px-4 xl:px-5">
        <div className="mx-auto flex flex-1 gap-4 text-base md:gap-5 lg:gap-6 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem]">
          {!isOutgoing && (
            <div className="flex-shrink-0 flex flex-col relative items-end">
              <div>
                <div className="pt-0">
                  <div className="gizmo-bot-avatar flex h- w-8 items-center justify-center overflow-hidden rounded-full">
                    <div className="relative p-1 rounded-sm flex items-center justify-center bg-token-main-surface-primary text-token-text-primary h-8 w-8">
                      <img
                        loading="lazy"
                        src="https://theolympiaschools.edu.vn/storage/favicon.png"
                        alt=""
                        className="object-contain shrink-0 w-6 aspect-[0.89]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="group/conversation-turn relative flex w-full min-w-0 flex-col">
            <MessageContent
              isOutgoing={isOutgoing}
              content={content}
              images={images}
              urls={urls}
              table={table}
              actions={actions}
              timestamp={timestamp}
              type={type}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
