export function formatDate(dateStr: string): string {
  const date = new Date(dateStr);

  // Mảng tên các tháng
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Lấy các phần của thời gian
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = months[date.getUTCMonth()]; // Lấy tên tháng
  let hours: number = date.getUTCHours();  // Giữ hours là number
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  // Xác định AM hoặc PM
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12; // Chuyển đổi giờ sang định dạng 12 giờ
  hours = hours || 12;  // Nếu giờ là 0 thì chuyển thành 12

  // Trả về định dạng hoàn chỉnh
  return `${day} ${month} ${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
}