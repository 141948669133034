// ImageActions.tsx
import React from "react";

interface ImageActionsProps {
  image: string;
  onFullscreen: (image: string) => void;
}

export const ImageActions: React.FC<ImageActionsProps> = ({ image, onFullscreen }) => {
  return (
      <div className="absolute inset-0 bg-gray-500/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
        {/* Fullscreen icon */}
        <button
            className="inline-flex items-center justify-center rounded-full h-8 w-8 bg-white/30 hover:bg-white/50 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 ml-2"
            onClick={() => onFullscreen(image)}
        >
          <svg className="w-4 h-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 14l-3 3m0 0l-3-3m3 3V10M15 14l3 3m0 0l3-3m-3 3V10"/>
          </svg>
        </button>
      </div>
  );
};
