import {FC} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import App from "../App";
import ChatPage from "../modules/chat/ChatPage";


const AppRoutes: FC = () => {
    return (
        <BrowserRouter basename='/'>
            <Routes>
                <Route path="/:org/*" element={<ChatPage />}>
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export {AppRoutes};
